/* You can add global styles to this file, and also import other style files */
@import '~handsontable/dist/handsontable.full.css';
/* Provide sufficient contrast against white background */
/*html, body {
  font-family: 'Exo' !important;
}*/
/*
label {
  font-size: smaller;
  font-weight: 500;
  margin-bottom: 0;
}

form {
  padding-right: 10px;
  padding-left: 10px;
}

form-group {
  margin-bottom: 0rem;
}

:root {
  --main-blue-color: #007ACD;
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


html, body {
  height: 100%;
}
*/
/*body {
  margin: 0;
  font-family: Exo, "Helvetica Neue", sans-serif;
}*/

.mat-tab-body-content {
  padding-top: 0px !important;
}

mat-tab-label, mat-tab-link {
  font-family: Exo, "Helvetica Neue", sans-serif !important;
}

.md-tabs-wrapper, .md-tabs-canvas, .md-pagination-wrapper, .mat-tab-labels {
  height: 35px !important;
}

/*.filaNegrita {
  font-width: 700;
}*/

.mat-dialog-title {
  margin: 0 0 20px;
  display: block;
  color: #007ACD;
}

input[type='checkbox'] {
  height: 20px !important;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 9999;
}


.dx-command-select {
  width: 20px !important;
  min-width: 20px !important;
}


.handsontable .colorFilaTitulo {
  background: #eeff00;
}

.container-fluid {
  //width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  flex-direction: row;
}

.text-center {
  text-align: center !important;
}

.col {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.round-container {
  border-radius: 25px !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.p-3 {
  padding: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.card-app {
  cursor: pointer;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, .18) !important;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: .3s cubic-bezier(.34, 1.61, .7, 1) !important;
  width: 220px;
  margin-left: 15px;
  margin-right: 15px;
}

.card .card-body {
  padding: .9375rem 20px;
  position: relative;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-body h3{
  text-align: center;
}

.app-card-icon .img-card {
  //border: 1px solid #b0bec5!important;
  border-radius: 50% !important;
  width: 65px !important;
  height: 65px !important;
  color: #607d8b;
  margin: auto !important;
}

img {
  vertical-align: middle;
  border-style: none;
}

.icons {
  width: 45px;
}

h3 {
  color: #0065c4;
  text-align: left;
}

p {
  font-size: 14px;
}

.row-sin-margen {

  display: flex;
  flex-wrap: wrap;
  /* margin-right: -15px; */
  /* margin-left: -15px; */
  flex-direction: row;

}

html, body {
  height: 100%;
}

/*body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
*/
.dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td {
  background-color: #edf7ff;
}

.back {
  position: absolute;
  margin-top: -8px;
  margin-left: -7px;
}

.form-label {
  //display: table-cell;
  font-size: 14px;
  margin-left: 65px;
  //min-width: 120px;
  color: #007acd;
  font-weight: 600;
}

table {
  width: 100%;
}

.mat-form-field {
  font-size: 14px;
  width: 100%;
}
main{
    padding: 20px;
}
.mat-form-field {
  width: 100%;
}
.mat-elevation-z8 {    
  overflow-x: auto;
 }
 .mat-error {
  color: #f44336;
  font-size: 12px;
}
.mat-form-field-wrapper {
  padding-bottom: 0.34375em;
}

[type=text]:focus, [type=number]:focus,input:where(:not([type])):focus{
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-ring-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-ring-shadow:white
}

.justify-between{
  justify-content: space-between;
}

/*START CSS MODAL*/
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(77, 101, 147, .25);
  border-radius: 9px;
  outline: 0;
  top: 40px;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dae0ec;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #dae0ec;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

@media (min-width: 992px){
  .modal-lg, .modal-xl {
      max-width: 800px;
  }

}

@media (min-width: 576px){ 
   .modal-dialog {
       max-width: 500px;
       margin: 1.75rem auto;
   }
}

.modal-xl{
  max-width: 1000px;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #212837;
}

.fade {
  transition: opacity .15s linear;
}

.h-\[660px\] {
  height: 660px;
}
.width-50{
  width: 50%;
}
/*END CSS MODAL*/